import React from 'react';
import Button from '../button';
import './style.scss';


const TeaserHighlight = ({post, readMore}) => {

  const imgContainerStyle = {
    background: `url(${post.heroImage ? post.heroImage.resolutions.src : ""})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }

  return (
    
    <div className="teaserPage">     
        <div className="teaserContent yellow">     
            <h3>{post.title}</h3>
            <p>{post.excerpt ? post.excerpt.excerpt : null}</p>
            <div className="teaserFooterContent">
              <Button link={`/${post.slug}`} text={readMore} color="orange" />  
              <img alt ="" src="/ramiPresenting.svg" className="ramiPresenting" />     
            </div> 
        </div>
        <div className="teaserImgContainer" style={imgContainerStyle}></div>
    </div>

  );
}
export default TeaserHighlight;