import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';

import './style.scss';

 const Video = (props) => {

  /* eslint-disable-next-line */
  const node = R.filter(item => item.node.slug == props.slug, props.data)[0].node;
  let url = "";
  if(node.type === "youtube") {
    url = `https://www.youtube.com/embed/${node.videoId}`;
  } else {
    url = `https://player.vimeo.com/video/${node.videoId}`;
  }
  return (
    <div className="video">
      <div className="videoContainer"> 
      <div className="metaContainer">
          <h2>{node.title}</h2>
        <p>{node.description.description}</p>
      </div>
    
        <div className="embedResponsiveContainer">
          <iframe 
            title={node.title}
            src={url}
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>
          </iframe>
        </div>
      </div>
    </div>
  );
}
export default connect(state => ({
  data: state.app.videos
}), null)(Video);