import React, { Component } from "react";

import "./style.scss";

class SomeShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ""
    };
  }

  componentDidMount() {
    const url = typeof window !== "undefined" ? window.location.href : "";
    this.setState({ url: url });
  }


  render() {

    const facebookUrl = `https://m.facebook.com/sharer/sharer.php?u=${
      this.state.url
    }`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${this.state.url}`;
    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${this.state.url}`
    return (
      <div className="share">
        <div className="someIcons">
          <a
            href={facebookUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Share on Facebook"
          >
            <img src="/facebook.svg" alt="Facebook" />
          </a>
          <a
            href={twitterUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Share on  Twitter"
          >
            <img src="/twitter.svg" alt="Twitter" />
          </a>

          <a
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Share on  Linkedin"
          >
            <img src="/linkedin.svg" alt="Linkedin" />
          </a>
         
        </div>
      
      </div>
    );
  }
}
export default SomeShare;