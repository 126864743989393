import React from 'react';
import CountryPromo from './country';
import CenteredCta from './centeredCta';
import { connect } from 'react-redux';
import * as R from 'ramda';


const PromoBlock = (props) => {
  /* eslint-disable-next-line */
  const reduxNode = R.filter(item => item.node.slug == props.slug, props.data)[0].node;

  return (
    <>
    {reduxNode.promoType === 'countrypromo' ? <CountryPromo node={reduxNode} /> : null}
    {reduxNode.promoType === 'centeredCta' ? <CenteredCta node={reduxNode} /> : null}
    </>
  )
}

export default connect(state => ({
  data: state.app.promoBlocks
}), null)(PromoBlock);