import React from 'react';
import TeaserPage from '../teaserPage';
import keygen from '../../utils/keygen';

const PageStyle = ({posts, title, showTitle, readMore}) => {

  return (
    <div className="relatedHighlightLayout">
      {showTitle ?
        <>
          <h2 className="relatedHeadline">{title}</h2>    
          <div className="reletedHeadlineDecoration"></div>
        </>
      : null}
      
        <div className="relatedContainer">

          {posts.map((post, index) => {
      
            return (
            <TeaserPage post={post} index={index} readMore={readMore} key={keygen()} />
            )
          })}
      
        </div>
    </div>
  );
}
export default PageStyle;