import React from 'react';


import './style.scss';

const TeaserCountryLink = ({post, readMore}) => {

  return (
    
    <div className="teaserCountryLink"  >
      <div className="teaserImgContainer">
        <img alt="" src={post.teaserImage ? post.teaserImage.resolutions.src : null} />
        <div className="teaserCategory">{post.category}</div>
      </div>
        <div className="teaserContent">
          <h3>{post.title}</h3>
          <p>{post.excerpt ? post.excerpt.excerpt : null}</p>
          {post.countryLink ? 
          <a href={post.countryLink} className="readMore" target="_blank" rel="noreferrer noopener">
            {readMore || 'Read more'} {post.countryName}
          </a>: null}
        </div>
     
    </div>

  );
}


export default TeaserCountryLink;