import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import * as R from 'ramda';
import keygen from '../../utils/keygen';

import SomeShare from '../someShare';

import './style.scss';

 const KeywordsAndSome = ({postSlug, slug, keywordsAndSome, posts}) => {
 
    // get current post keywords
    const postSlugMatch = (item) => item.node.slug === postSlug;
    const keywords = R.head(R.filter(postSlugMatch, posts)).node.keywords;
    /* eslint-disable-next-line */
    const node = R.filter(item => item.node.slug == slug, keywordsAndSome)[0].node;
  return (
    <div className="keywords">
      {node.keywordsLabel ?   
      <h2>{node.keywordsLabel}</h2> :null}
      {node.showKeywords ?
      <div>
      
        {keywords.map(keyword => {
          return (
            <Link key={keygen()} to={`/keywords?word=${keyword}`} className="keyword">{keyword}</Link>
          );
        })}
      </div>: null}  
      {node.someHeadlineLabel ?
      <h2>{node.someHeadlineLabel}</h2> :null}
      {node.showSomeShare ? 
        <SomeShare /> 
      : null}
    </div>
  );
}
export default connect(state => ({
  keywordsAndSome: state.app.keywordsAndSome,
  posts: state.app.posts
}), null)(KeywordsAndSome);