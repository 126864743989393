import React from 'react';
import TeaserPictogram from '../teaserPictogram';
import keygen from '../../utils/keygen';

const Pictogram = ({posts, title, bgColor, color, showTitle}) => {
  const inlineStyles = {
    color: color,
    background: bgColor
  }
  return (
    <div className="relatedPictogramLayout" style={inlineStyles}>
      {showTitle ?
        <h2 style={{color: color}} className="relatedHeadline">{title}</h2> 
      : null}
      
        <div className="relatedContainer">

          {posts.map(post => {
            return (
            <TeaserPictogram key={keygen()} post={post} />
            )
          })}
      
        </div>
    </div>
  );
}
export default Pictogram;