import React from 'react';
import L from 'leaflet';
import * as R from 'ramda';
import europe from '../../../assets/map/europe.json';
import ramicountries from '../../../assets/map/ramicountries.json';
import "./style.scss";



class Map extends React.Component {


  componentDidMount() {
    
    if(typeof window !== undefined) {

      
      this.recycleIcon = L.icon({
        iconAnchor: [14, 40]
      });
      
      this.map = L.map(this.props.mapId, {
        center: [57.0, 29.0],
        zoom: 3,
        maxZoom: 3,
        minZoom: 3,
        preferCanvas: false,
        zoomControl: false,
        scrollWheelZoom: false,
        maxBounds: new L.LatLngBounds(new L.LatLng(49.5, -11.3), new L.LatLng(61.2, 43.0)),
        layers: [
        ]
      });      
      
       L.geoJson(europe, {
        style: {color: '#ffdc00', fillColor: '#fff', fillOpacity: 1, weight: 1,
        opacity: 1,},
      }).addTo(this.map);

      this.ramiGeoJsonLayer =  L.geoJson(ramicountries, {
        style: {color: '#ffdc00', fillColor: '#003287', fillOpacity: 1, weight: 1,
        opacity: 1,},
      }).addTo(this.map);

      this.ramiGeoJsonLayer.eachLayer((layer) => {
        if(this.props.countries) {
        /* eslint-disable-next-line */
        const countryCf = R.filter(item => item.name == layer.feature.properties.name, this.props.countries);
        layer.bindTooltip(layer.feature.properties.name, {
          permanent: false
          });
        layer.on('click', () => {
          window.open(countryCf[0].link, "_blank");
        });
      }
      });
    
    }
  }
 

  render() {
    return (
      <div className="frcMap">
        <div id={this.props.mapId}></div>
      </div>
    )
    
    
  }
}


export default Map;