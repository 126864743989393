import React, { Component } from 'react'
import keygen from '../../utils/keygen';
import 'intersection-observer';
import { InView } from 'react-intersection-observer';

class Number extends Component {
  constructor(props) {
    super(props);
    this.state = {num: 0};
  }

  componentDidMount() {
    this.speed = 1;
    this.increment = 1;
    if(this.props.num > 1000) {
      this.speed = 1;
      this.increment = 10
    } else if (this.props.num > 100) {
      this.speed = 10;
      this.increment = 2
    } else {
      this.speed = 100;
    }
  
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    if(this.state.num < this.props.num && (this.state.num + this.increment) < this.props.num) {
      this.setState({
      num: this.state.num + this.increment
    });
    } else if(this.state.num < this.props.num) {
      this.setState({
        num: this.state.num + 1
      });
    }
    
  }
    
  render() {
    const onChange = (isVisible) =>{
      if(isVisible) {
        this.timerID = setInterval(
          () => this.tick(),
          this.speed
        );
      }
      
    }
    return (
    <InView onChange={onChange} className="numberElementOuter"> 
      <div key={keygen()} className="numberElement">
        <div className="animatedNumber">{this.state.num}</div>
        <div>{this.props.label}</div>   
      </div>
    </InView>
    )
  }
}

export default Number;