import React, { Component } from 'react';
import { connect } from 'react-redux';
import keygen from '../../../utils/keygen';
import Map from './map';
import Button from '../../button';
import './style.scss';

class CountryPromo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showCountries: false,
      randomMapId: 'map' + Math.floor(Math.random() * 99999),
      countryName: '',
      countryLink: '',
    }
  }


  static getDerivedStateFromProps(props, state) {
    if (props.userLocation.name !== state.countryName && props.node.promoBlockData) {
      let name = '';
      let link = '';
      props.node.promoBlockData.countries.forEach(country => {
        /* eslint-disable-next-line */
        if(country.name == props.userLocation.name) {
          name = country.name;
          link = country.link;
        }
      });
      return {
        countryName: name,
        countryLink: link
      };
     }}
     
render() {


  const showCountriesClickHandler = () => {
    this.setState({
      showCountries: !this.state.showCountries
    })
  }
  return (
    <div className="countryPromoBlock">
      <div className="countryControls">

      { /* eslint-disable-next-line */
      this.props.userLocation.country == '' ? 
        <div>
          <h3>{this.props.node.title}</h3>

        </div> 
      :null}

     { this.props.userLocation.country !== '' ? 
        <div>
          <h3>{this.props.node.title}</h3>
          <a href={this.state.countryLink} rel="noopener noreferrer" target="_blank" className="countryCtaLink">
            Go to Ramirent {this.state.countryName} <img src="/arrow-right.svg" alt="" />
          </a>
        </div> 
      : null}

      

        <button
          onClick={showCountriesClickHandler} 
         className="chooseCountryBtn">
          {this.state.countryName !== '' ? 'Choose another country' : 'Choose a country'}
        </button>
        <ul>
          {this.props.node && this.state.showCountries ?
          this.props.node.promoBlockData.countries.map(country => {
            return (
            <li key={keygen()}><a href={country.link} target="_blank" rel="noopener noreferrer">{country.name}</a></li>
            );
          }): null}
          
        </ul>
        {this.props.node.description ?
        <div dangerouslySetInnerHTML={{ __html: this.props.node.description.childMarkdownRemark.html }}/>
        : null}
        {this.props.node.ctaLink1 ?
        <Button link={this.props.node.ctaLink1}text={this.props.node.ctaText1} color="orange" />
        : null}
      </div>
      {typeof window !== "undefined" ?
      <Map countries={this.props.node && this.props.node.promoBlockData ? this.props.node.promoBlockData.countries : null} mapId={this.state.randomMapId} /> : null}
    </div>
  );
}
  
}


export default connect(state => ({
  userLocation: state.app.userLocation
}), null)(CountryPromo);

