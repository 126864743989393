import React from 'react';
import { connect } from 'react-redux';
// import { Link } from 'gatsby';
import * as R from 'ramda';

import './style.scss';


const Author = ({ slug, people, postSlug, posts }) => {


  // get author component data
  const slugMatch = (item) => item.node.slug === slug;
  const author = R.head(R.filter(slugMatch, people)).node;

  return (

    <div className="authorCard">
      <div className="authorCardContainer">
        <div className="authorCardContent">

          <h2>{author.title}</h2>
          
              {author.jobTitle ? author.jobTitle : null}<br />
              {author.unit ? author.unit : null}<br />
              {author.phone ? author.phone : null}<br />
              {author.email ? author.email : null}<br />
              <p>{author.description.description}</p>

        </div>
        <div className="authorImageContainer">
          <img className="authorImage" src={author.teaserImage ? author.teaserImage.resolutions.src : ''} alt={author.title} />
        </div>
        
      </div>
    </div>

  );
}
export default connect(state => ({
  people: state.app.people,
  posts: state.app.posts
}), null)(Author);