import React from 'react';
import Button from '../../button';

import './style.scss';

const CenteredCta = ({node}) => {
  return (
    <div className="centeredCta">
      <div className="centeredCtaContainer">
        <img src="/ramihead.svg" className="centeredRamiHead" alt="" />
        <h2>{node.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: node.description ? node.description.childMarkdownRemark.html : null }}/>
        {node.ctaLink1 ?
          <Button link={node.ctaLink1} text={node.ctaText1} color="blue" marginX />
        :null}
        {node.ctaLink2 ?
          <Button link={node.ctaLink2} text={node.ctaText2} color="blue" marginX />
        :null}
      </div>
    </div>
  );
}

export default CenteredCta;