import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'gatsby';
import * as R from 'ramda';
import Standard from './standard';
import Pictogram from './pictogram';
import Quicklink from './quicklink';
import Highlight from './highlight';
import PageStyle from './page';
import CountryLink from './countryLink';


import './style.scss';


const Related = ({ 
  slug, // slug of relatedPost
  title, // title of related post
  relatedPosts, // all relatedPosts from redux
  posts,// all posts from redux
 }) => {

    // This checks from slug value which relatedPost to pick from all of them
   const slugMatch = (arr) => arr.node.slug === slug;
   // The chosen relatedPost item that can include posts, categorychoises and number of posts
   let related = R.path(['node'], R.head(R.filter(slugMatch, relatedPosts)));
  
    // Individually picked posts from this relatedPost object in order they been put to array in CF
   const chosenPosts = R.path(['posts'], related);
    // Individually picked links from this relatedPost object in order they been put to array in CF
    const chosenLinks = R.path(['links'], related);
    // Individually picked pages from this relatedPost object in order they been put to array in CF
    const chosenPages = R.path(['pages'], related);
    // Individually picked pages from this relatedPost object in order they been put to array in CF
    const chosenPeople = R.path(['people'], related);
    // Individually picked pictograms from this relatedPost object in order they been put to array in CF
    const chosenPictograms = R.path(['pictograms'], related);
    // Individually picked pictograms from this relatedPost object in order they been put to array in CF
    const chosenCountryLinks = R.path(['countryLinks'], related);
    // Chosen categories
    const category = R.path(['category'], related);
   // Chosen number of posts
   const numberOfPosts = R.path(['numberOfPosts'], related);
  // Filter posts based on category and sort them by date
  const catMatch = (arr) => arr.node.category === category;
  let postsByCat = R.filter(catMatch, posts);
  // sort by date newest first
  postsByCat = postsByCat.sort((a, b) => new Date(b.node.date) - new Date(a.node.date));
  // limit to number of posts chosen
  const offset = R.pathOr(0, ['offset'], related);
  const getRightAmountOfPosts = (posts, number) => {
    if(number && number !== 0) {
      return posts.slice(offset, number);
    }  else return posts;
  }
  postsByCat = getRightAmountOfPosts(postsByCat, numberOfPosts);
  // get post from node element
  postsByCat = postsByCat.map(post => {return post.node});
  
  const teaserStyle = related.style || "standard";
  const ctaLabel = related.ctaLabel || "";
  const ctaLink = related.ctaLink || "";
  const getBgColor = () => {
    switch (teaserStyle) {
      case 'pictogramWithBlueBackground':
        return '#003287';
      case 'pictogramWithYellowBackground':
        return '#ffdc00';
      default:
        return '#fff';
    }
  }
  const bgColor = getBgColor();

  const color = bgColor === "#003287" ? '#fff' : "#003287";
  const postsToShow = chosenPosts ? chosenPosts : postsByCat;
  const readMore = related.readMoreLabel || 'Read more';
  return(
    <div className="relatedComponent">
    {teaserStyle === "standard" && postsToShow ?
      <Standard posts={postsToShow} title={title} showTitle={related.showTitle} />:null}

    {teaserStyle === "highlight" && postsToShow ?
      <Highlight posts={postsToShow} title={title} showTitle={related.showTitle} readMore={readMore} />:null} 

    {chosenPeople && teaserStyle === "people" ?
      <Standard posts={chosenPeople} title={title} showTitle={related.showTitle} people={true} />:null}

    {(chosenPictograms && teaserStyle === "pictogram") || (chosenPictograms && teaserStyle === "pictogramWithBlueBackground") || (chosenPictograms && teaserStyle === "pictogramWithYellowBackground")?
      <Pictogram posts={chosenPictograms} title={title} showTitle={related.showTitle} bgColor={bgColor} color={color}/>:null}

    {teaserStyle === "quicklink" && chosenLinks ?
      <Quicklink links={chosenLinks} title={title} showTitle={related.showTitle} color="yellow" />:null}
    
    {teaserStyle === "quicklinkGrey" && chosenLinks ?
      <Quicklink links={chosenLinks} title={title} showTitle={related.showTitle} color="grey" />:null}

    {teaserStyle === "page" && chosenPages ?
      <PageStyle posts={chosenPages} title={title} showTitle={related.showTitle} readMore={readMore} />:null} 
    
    {chosenCountryLinks && teaserStyle === "countrySiteLink" ?
      <CountryLink posts={chosenCountryLinks} title={title} showTitle={related.showTitle} readMore={readMore} />:null} 
      
      <div className={`${ctaLink !== '' && ctaLabel !== '' ? 'relatedCta' : ''}`}>
        {!ctaLink.includes('http') && ctaLink !== "" && ctaLabel !== "" ?
        <Link className="relatedCtaLink"  to={ctaLink} ><span>{ctaLabel}</span> <img src="/arrow-right.svg" alt="" /></Link>
        : null}

        {ctaLink.includes('http') && ctaLabel !== "" ?
        <a className="relatedCtaLink" href={ctaLink} ><span>{ctaLabel}</span> <img src="/arrow-right.svg" alt="" /></a>
        : null}

      </div>
   
    </div>
  )
}


export default connect(state => ({
  relatedPosts: state.app.relatedPosts,
  posts: state.app.posts
}), null)(Related);