/**
 * This block renders Contentful rich text, based on slug given as prop
 */
import React from 'react';
import { connect } from 'react-redux'
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as R from 'ramda';

import './style.scss';

const Content = ({slug, articleContent}) => {
  const slugMatch = (arr) => arr.slug === slug;
  const content = R.head(R.filter(slugMatch, articleContent.nodes));
  const style = content.style ? content.style : 'normal';
  const markdownHtml = R.pathOr('', ['markdownContent', 'childMarkdownRemark', 'html'], content);
  const options = {
    // here you can change default rendering and add one if missing
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {

        const url  = R.path(['data', 'target', 'fields', 'file', 'en-US', 'url'], node);
        return <img alt={R.path(['data', 'target', 'fields', 'title', 'en-US'], node)} src={`https:${url}`} />
      }
    }
  };

  return (
    <div className={`articleContent ${style === 'infobox' ? 'infobox' : null}`}>
    {content.content ? documentToReactComponents(content.content.json, options): null}
    <div dangerouslySetInnerHTML={{ __html: markdownHtml ? markdownHtml : null }}/>
    </div>
  );
}
export default connect(state => ({
  articleContent: state.app.articleContent
}), null)(Content);



