import React from 'react';
import Teaser from '../teaser';
import keygen from '../../utils/keygen';

const Standard = ({posts, title, showTitle, people}) => {

  return (
    <div className="relatedStandardLayout">
      {showTitle ?
        <>
          <h2 className="relatedHeadline">{title}</h2>    
          <div className="reletedHeadlineDecoration"></div>
        </>
      :null}
        <div className="relatedContainer">

          {posts.map(post => {
            return (
            <Teaser key={keygen()} post={post} people={people} />
            )
          })}
      
        </div>
    </div>
  );
}
export default Standard;