import React from 'react';
import Related from '../related';
import Content from '../content';
import PromoBlock from '../promoblock';
import Author from '../author';
import Video from '../video';
import Testimonial from '../testimonial';
import Numbers from '../numbers';
import KeywordsAndSome from '../keywordsAndSome';
import ColumnContent from '../columnContent';

import keygen from '../../utils/keygen';

const ComponentPicker = ({ type, component, postSlug }) => {

 
  const Component = () => {
    switch (type) {
      case 'ContentfulRelatedPosts':
        return (<Related key={keygen()} slug={component.slug} title={component.title} style={component.style} />);
      case 'ContentfulArticleContent':
        return (<Content key={keygen()} slug={component.slug} />);
      case 'ContentfulPromoBlock':
        return (<PromoBlock key={keygen()} slug={component.slug} />);
      case 'ContentfulPeople':
        return (<Author key={keygen()} slug={component.slug} />);
      case 'ContentfulVideo':
        return (<Video key={keygen()} slug={component.slug} />);
      case 'ContentfulTestimonial':
        return (<Testimonial key={keygen()} slug={component.slug} />);
      case 'ContentfulColumnContent':
        return (<ColumnContent key={keygen()} slug={component.slug} />);
      case 'ContentfulSomeAndKeywordsDisplay':
        return (<KeywordsAndSome key={keygen()} slug={component.slug} postSlug={postSlug} />);
      case 'ContentfulNumbers':
        return (<Numbers key={keygen()} slug={component.slug} />);
      default:
        return null;
    }
  }

  

  return (
    <>
      <Component />
    </>
  );

}
export default ComponentPicker