import React from 'react'
import keygen from '../../utils/keygen';
import Number from './number';
import { connect } from 'react-redux';
import * as R from 'ramda';

import './style.scss';

const Numbers = (props) => {
  
  /* eslint-disable-next-line */
  const node = R.filter(item => item.node.slug == props.slug, props.data)[0].node;

 
    return (
      <div key={keygen()} className="numbersComponent">
        {node.number1 ?
        <Number num={node.number1} label={node.label1} />:null}
         {node.number2 ?
        <Number num={node.number2} label={node.label2} />:null}
         {node.number3 ?
        <Number num={node.number3} label={node.label3} />:null}
      </div>
    );
  
}

export default connect(state => ({
  data: state.app.numberBlocks
}), null)(Numbers);