import React from 'react';
import Button from '../button';
import isEven from '../../utils/iseven';
import './style.scss';


const TeaserHighlight = ({post, index, readMore}) => {

  const imgContainerStyle = {
    background: `url(${post.teaserImage ? post.teaserImage.resolutions.src : ""})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }

  return (
    
    <div className="teaserHighlight">
      {!isEven(index) ?
        <div className="teaserContent yellow">
      
            <h3>{post.title}</h3>
            <p>{post.excerpt ? post.excerpt.excerpt : null}</p>
            <div className="teaserCategory">{post.category}</div>
            <Button link={`/content-hub/${post.slug}`} text={readMore} color="blue" />
            
         
        </div>:null}
      <div className="teaserImgContainer" style={imgContainerStyle}>
              
      </div>
      {isEven(index) ?
        <div className="teaserContent blue">
          
            <h3>{post.title}</h3>
            <p>{post.excerpt ? post.excerpt.excerpt : null}</p>
            <div className="teaserCategory">{post.category}</div>
            <Button link={`/content-hub/${post.slug}`} text={readMore} color="yellow" />
          
        </div>:null}
    </div>

  );
}
export default TeaserHighlight;