import React from 'react';
import { connect } from 'react-redux';
import TeaserCountryLink from '../teaserCountryLink';
import keygen from '../../utils/keygen';
import * as R from 'ramda';

const CountryLink = ({posts, title, showTitle, userLocation, readMore}) => {
  
  const postsWithCountryLinks = posts.map(post => {
    if(post.countrySiteLinks) {
   
     const match = (item) => item.includes(userLocation.site);
      const link = R.head(R.filter(match, post.countrySiteLinks));
      post.countryLink = link; 
      post.countryName = userLocation.name;
    }
    
    return post;
  })

  return (
    <div className="relatedStandardLayout">
      {showTitle ?
        <>
          <h2 className="relatedHeadline">{title}</h2>    
          <div className="reletedHeadlineDecoration"></div>
        </>
      :null}
        <div className="relatedContainer">

          {postsWithCountryLinks.map(post => {
          
             return (
            <TeaserCountryLink key={keygen()} post={post} readMore={readMore} />
            ) ;
            
            
          })}
      
        </div>
    </div>
  );
}

export default connect(state => ({
  userLocation: state.app.userLocation
}), null)(CountryLink);