import React from 'react';

import './style.scss';

const TeaserPictogram = ({post}) => {

  return (
    
    <div className="teaserWithPictogram">
      <div className="teaserImgContainer">
        <img alt="" src={post.teaserImage ? post.teaserImage.resolutions.src : null} />
      </div>
        <div className="teaserContent">
          <h3>{post.title}</h3>
          <p>{post.excerpt ? post.excerpt.excerpt : null}</p>
        </div>
    </div>

  );
}
export default TeaserPictogram;