import React from 'react';
import { connect } from 'react-redux'
import * as R from 'ramda';

import './style.scss';

const Content = ({slug, columnContent}) => {
  
  const slugMatch = (edge) => edge.node.slug === slug;
  const columns = R.head(R.filter(slugMatch, columnContent));

  const col1 = R.path(['node', 'column1', 'childMarkdownRemark', 'html'], columns);
  const col2 = R.path(['node', 'column2', 'childMarkdownRemark', 'html'], columns);
  const col3 = R.path(['node', 'column3', 'childMarkdownRemark', 'html'], columns);

  return (
    <div className="columnContent">
      <div className="columnGrid">
        {col1 ? <div className="flexCol" dangerouslySetInnerHTML={{ __html: col1 }}  /> : null}
        {col2 ? <div className="flexCol"  dangerouslySetInnerHTML={{ __html: col2 }}  /> : null}
        {col3 ? <div className="flexCol"  dangerouslySetInnerHTML={{ __html: col3 }}  /> : null}
      </div>
    </div>
  );
}
export default connect(state => ({
  columnContent: state.app.columnContent
}), null)(Content);