import React from 'react';
import { Link } from 'gatsby';
import keygen from '../../utils/keygen';

const Quicklink = ({links, title, showTitle, color}) => {

  const quicklinkClass = color === 'grey' ? 'grey' : 'yellow';

  return (
    <div className="relatedQuicklinkLayout">
      {showTitle ?
        <h2 className="relatedHeadline">{title}</h2> 
      : null}
      
        <div className="relatedContainer">

          {links.map(link => {
            return (
            link.link.includes('http') ? 
              <a className={quicklinkClass} key={keygen} href={link.link} target="_blank" rel="noreferrer">{link.title}</a>
            : 
              <Link className={quicklinkClass} key={keygen()} to={link.link}>{link.title}</Link>);
            
          })}

          
      
        </div>
    </div>
  );
}
export default Quicklink;