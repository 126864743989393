import React from 'react'
import { connect } from 'react-redux';
import * as R from 'ramda';

import './style.scss';

const Testimonials = (props) => {
  
  /* eslint-disable-next-line */
  const node = R.filter(item => item.node.slug == props.slug, props.data)[0].node;

    return (
      <div  className="testimonial">
        <div className="quote">
          <p>"{node.quote.quote}"</p>
          <div className="personName">- {node.name}</div> 
        </div>
       <div className="imageContainer">
        <img alt="" src={node.image ? node.image.resolutions.src : ''} />
       </div>
      </div>
    );
  
}

export default connect(state => ({
  data: state.app.testimonials
}), null)(Testimonials);