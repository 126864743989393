import React from 'react';
import TeaserHighlight from '../teaserHighlight';
import keygen from '../../utils/keygen';

const Highlight = ({posts, title, showTitle, readMore}) => {

  return (
    <div className="relatedHighlightLayout">
      {showTitle ?
        <>
          <h2 className="relatedHeadline">{title}</h2>    
          <div className="reletedHeadlineDecoration"></div>
        </>
      : null}
      
        <div className="relatedContainer">

          {posts.map((post, index) => {
      
            return (
            <TeaserHighlight key={keygen()} post={post} readMore={readMore} index={index} />
            )
          })}
      
        </div>
    </div>
  );
}
export default Highlight;